import styles from '../styles/Navbar.module.css';

function Navbar() {
  return (
    <>
        <nav className={styles.navbar}>
            <div className={styles.logo}>
                <a href="/">
                    <img className={styles.navbar_img} src="/res/favicon.png" alt="logo" />
                </a>
            </div>
        </nav>
    </>
  )
}

export default Navbar;