import styles from '../styles/CV.module.css';
import { useEffect } from 'react';

function CV() {
  useEffect(() => {
    window.location.href = 'res/Omar Gamal.pdf';
  }, []);

  return null;
}

export default CV;