import { useState, useEffect } from 'react';
import styles from '../styles/Page.module.css';
import Button from './Button';
import Gallery from './Gallery';

const OJEE = process.env.REACT_APP_OJEE;
const BLUEF1AG = process.env.REACT_APP_BLUEF1AG;

function Home() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    const bf1_images = [
        'res/f1/f1.png',
        'res/f1/f2.png',
        'res/f1/f3.png',
        'res/f1/f4.png',
        'res/f1/f5.png',
        'res/f1/f6.png',
        'res/f1/f7.png',
        'res/f1/f8.png',
        'res/f1/f9.png',
        'res/f1/f10.png',
        'res/f1/f11.png',
        'res/f1/f12.png',
        'res/f1/f13.png',
        'res/f1/f14.png',
        'res/f1/f15.png',
        'res/f1/f16.png',
        'res/f1/f17.png',
        'res/f1/f18.png',
        'res/f1/f19.png',
        'res/f1/f20.png',
        'res/f1/f21.png'
    ];
    
    const ndb_images = [
        'res/ndb/1.gif',
        'res/ndb/2.png',
        'res/ndb/3.png',
        'res/ndb/4.png',
        'res/ndb/5.png',
        'res/ndb/6.png',
        'res/ndb/7.png',
        'res/ndb/8.png',
        'res/ndb/9.png',
        'res/ndb/10.png',
        'res/ndb/11.png',
        'res/ndb/12.png',
        'res/ndb/13.png',
        'res/ndb/14.png',
        'res/ndb/15.png'
    ];
    
    const lipton_images = [
        'res/lipton/1.png',
        'res/lipton/2.png',
        'res/lipton/3.png',
        'res/lipton/4.png',
        'res/lipton/5.png',
        'res/lipton/6.png'
    ];

    const muw_images = [
        'res/muw/img1.png',
        'res/muw/img2.png',
        'res/muw/img3.png'
    ];
    
    return (
        <>
            {/* <div className={styles.div} style={{height: isMobile ? '80vh' : '180vh'}}> */}
            <div className={styles.div} style={{height:'auto', marginTop:'75px', marginBottom:'100px'}}>
                <p className={styles.intro} style={{textAlign:'center', marginRight:'25px', marginLeft:'25px', marginBottom:'100px'}}>HI! I'M OJEE!<br />CSEN STUDENT, PROGRAMMER, AND ENGINEER.</p>
                <div className={styles.div} style={{gap:'100px'}}>
                    <div className={styles.div}>
                        {/* blue f1ag */}
                        <a href={"https://" + BLUEF1AG}>
                            <Button text="Blue F1ag →" img="res/icons/bf1-icon.png" type="normal"/>
                        </a>
                        <p className={styles.desc} style={{textAlign:'center', marginRight:'25px', marginLeft:'25px'}}>Blue F1ag provides F1 data through graphs for fans to analyze.</p>
                        <div style={{width: isMobile ? '90%' : '75%'}}>
                            <Gallery images={bf1_images}/>
                        </div>
                    </div>
                    <div className={styles.div}>
                        {/* ndb */}
                        {/* <a target="_blank" rel='noreferrer' href="/ndb"> */}
                        <a target="_blank" rel='noreferrer' href="https://github.com/0J33/nile-delta-bank">
                            <Button text="Nile Delta Bank →" img="res/icons/ndb.png" type="normal"/>
                        </a>
                        <p className={styles.desc} style={{textAlign:'center', marginRight:'25px', marginLeft:'25px'}}>CSEN603 (Software Engineering) Bank Website Project</p>
                        <div style={{width: isMobile ? '90%' : '75%'}}>
                            <Gallery images={ndb_images}/>
                        </div>
                    </div>
                    <div className={styles.div}>
                        {/* lipton */}
                        {/* <a target="_blank" rel='noreferrer' href="/lipton"> */}
                        <a target="_blank" rel='noreferrer' href="https://github.com/0J33/Lipton-Appreciation">
                            <Button text="Lipton Appreciation →" img="res/icons/lipton.png" type="normal"/>
                        </a>
                        <p className={styles.desc} style={{textAlign:'center', marginRight:'25px', marginLeft:'25px'}}>Lipton Appreciation Website</p>
                        <div style={{width: isMobile ? '90%' : '75%'}}>
                            <Gallery images={lipton_images}/>
                        </div>
                    </div>
                    <div className={styles.div}>
                        {/* muw */}
                        {/* <a target="_blank" rel='noreferrer' href="/muw"> */}
                        <a target="_blank" rel='noreferrer' href="https://github.com/0J33/MUW">
                            <Button text="Marvel - Ultimate War →" img="res/icons/ca.png" type="normal"/>
                        </a>
                        <p className={styles.desc} style={{textAlign:'center', marginRight:'25px', marginLeft:'25px'}}>CSEN401 Game Project</p>
                        <div style={{width: isMobile ? '90%' : '75%'}}>
                            <Gallery images={muw_images}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;