import styles from '../styles/Contact.module.css';

function Contact() {
    return (
        <div className={styles.contact}>
            <a target="_blank" rel='noreferrer' href="https://www.linkedin.com/in/omargamaleldin/"><img className={styles.img} src="res/icons/linkedin.png" alt=""/></a>
            <a target="_blank" rel='noreferrer' href="https://github.com/0J33/"><img className={styles.img} src="res/icons/git.png" alt=""/></a>
            <a target="_blank" rel='noreferrer' href="https://stackoverflow.com/users/19247380/ojee"><img className={styles.img} src="res/icons/stackoverflow.png" alt=""/></a>
            <a target="_blank" rel='noreferrer' href="https://buymeacoffee.com/ojee"><img className={styles.img} src="res/icons/bmac.png" alt=""/></a>
            <a target="_blank" rel='noreferrer' href="mailto:contact@ojee.net"><img className={styles.img} src="res/icons/mail.png" alt=""/></a>
            <a target="_blank" rel='noreferrer' href="/cv"><img className={styles.img} src="res/icons/cv.png" alt=""/></a>
        </div>
    )
} 

export default Contact;