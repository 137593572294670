import styles from '../styles/Playlists.module.css';

function Playlists() {
    return (
        <div className={styles.playlists}>
            <h1>Main</h1>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/5AcvQ2DBxD7MyL3d4GiIZz?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/5ZHpFt17Xtvjmqef0Bllh3?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <h1>EDM</h1>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/641pGZac2ANO99zQixmsyV?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/5GX0ivJ7XYtA8p0sGWZYer?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/7Ge9gJBxKJwGM5X73BTzMw?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/5ANiQEv5Oh2Z3jLODiUN65?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <h1>Auto</h1>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/3DDxvp8LtJfseKYfq9vZZy?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/3oREXVPfUIr7GZDCNFkAFi?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/0j827BVWzOtd2uUMRlkBHO?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/08Y7qNICXoYReAoXE94oYt?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/6IP7RDUQ60Qc09Qbu0hbyV?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/38yeEkxL3B4nRrHamzOoTm?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <h1>Rap</h1>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/3KcJe3ngtUsXs3aoYikkGp?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/4Og4oXOHuadcLmcmRNQR0R?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/7ET7DNR7yD0L3HamQqVGQv?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/49O92So98EUzLLriERg5ev?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/7LkZbiJp1VzNBbiNRrOeyB?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/2DFeK0jjYajMTBBfYd4xUb?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/6xEMo5mPquuTsM4pTmhEnR?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <h1>Mood</h1>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/3wog1Mfs4t7YGMpa9CY7zs?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/1OvUdpEY8iwRXA4RLzzrIJ?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/5deZZdXzbf3w1zYxxxdaoe?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/6cunvIAAddGGAZwbYmBqXT?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/2PKc4VHPcINGtrHsJeyS3S?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/3gFtpHSUWZU8OXVLqBpHM6?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <h1>IATA</h1>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/6ISJDOQO12JbbdsS1WXuBE?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <iframe style={{"border-radius":"12px"}} src="https://open.spotify.com/embed/playlist/5fztfsybIwNAfJghjuEIhF?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            <br />
        </div>
    )
} 

export default Playlists;