// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import CV from './components/CV';
import Playlists from './components/Playlists';
import styles from './styles/App.module.css';

const OJEE = process.env.REACT_APP_OJEE;
const BLUEF1AG = process.env.REACT_APP_BLUEF1AG;

function App() {
    return (

        <>
            <Navbar />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/bluef1ag" redirectTo={BLUEF1AG} />
                    <Route path="/bluef1ag-tos" redirectTo={BLUEF1AG + "/tos"} />
                    <Route path="/bluef1ag-priv" redirectTo={BLUEF1AG + "/priv"} />
                    <Route path="/bluef1ag-web" redirectTo={BLUEF1AG + "/web"} />
                    <Route path="/cv" element={<CV />} />
                    <Route path="/playlists" element={<Playlists />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </Router>
            <Contact />
        </>
        
    );
}

export default App;